@import "@/assets/scss/style.scss";

.custom-search {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .anticon {
        font-size: 40px;
    }
}
